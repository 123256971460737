import React from 'react';
import HomePage from "./HomePage";
import './css/main.css';
import {createRoot} from "react-dom/client";

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HomePage />
    </React.StrictMode>,
);
