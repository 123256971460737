import React, { useState } from 'react';
import axios from 'axios';

const Login = ({ onCredentialsChange }) => {
    const [email, setEmail] = useState('');
    const [pw, setPw] = useState('');
    const handleEmailChange = ({ target: { value } }) => {
        setEmail(value);
        onCredentialsChange({ email: value, pw });
    };

    const handlePasswordChange = ({ target: { value } }) => {
        setPw(value);
        onCredentialsChange({ email, pw: value });
    };



    return (
        <div className="login-container">
            <div className="input-group">
                <label htmlFor="email">Email</label>
                <input type="text" id="email" value={email} onChange={handleEmailChange} autoComplete="username" />
            </div>
            <div className="input-group">
                <label htmlFor="pw">Password</label>
                <input type="password" id="pw" value={pw} onChange={handlePasswordChange} autoComplete="current-password" />
            </div>
        </div>
    );
};


export default Login;